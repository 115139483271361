<template>
	<div id="app">
		<Header />
		<Nav />
		<router-view />
		<Footer />
	</div>
</template>
<script>
	import Header from "./components/Header";
	import Nav from "./components/Nav";
	import Footer from "./components/Footer"
	export default {
		components: {
			Header,
			Nav,
			Footer
		},
	};
</script>
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	.el-tabs__item:hover {
	    color: #b80101!important;
	    cursor: pointer;
	}
	.el-tabs__item.is-active {
	    color: #b80101!important;
	}
	.el-tabs__content {
		padding-top: 15px;
		min-height: 377px !important;
	}
	.el-tabs__active-bar {
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    height: 2px;
	    background-color: #b80101!important;
	    z-index: 1;
	    transition: transform .3s cubic-bezier(.645,.045,.355,1);
	    list-style: none;
	}
	.el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
		color: #fff !important;
		background-color: #039705 !important;
	}

	.el-tabs--border-card>.el-tabs__header .el-tabs__item {
		/* width: 158px !important; */
		text-align: center;
		font-size: 14px;
		height: 30px;
		line-height: 30px;
	}

	.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		color: #fff !important;
		background-color: #039705 !important;
		border-right-color: #039705 !important;
		border-left-color: #039705 !important;
	}

	.el-radio__input.is-checked .el-radio__inner {
		border-color: #d0131c !important;
		background: #d0131c !important;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	   border-color: #d0131c !important;
	   background: #d0131c !important;
	}
	.el-checkbox__input.is-checked+.el-checkbox__label {
	    color: #d0131c !important;
	}
	.el-radio.is-bordered.is-checked {
		border-color: #d0131c !important;
	}
	.el-radio__input.is-checked+.el-radio__label {
	    color: #d0131c !important;
	}
	.el-button--primary:focus, .el-button--primary:hover {
	    background: #d0131c !important;
	    border-color: transparent !important;
	    color: #FFF;
	}
	.el-button--primary:focus, .el-button--primary{
	    background: #d0131c !important;
	    border-color: transparent !important;
	    color: #FFF;
	}
	.el-button:focus, .el-button:hover {
	    color: #fff !important;
	    border-color: transparent !important;
	    /* background-color: #d0131c !important; */
	}
	
	.el-select .el-input__inner {
		height: 28px !important;
	}

	.el-input__icon {
		line-height: 28px !important;
	}

	.el-pagination {
		white-space: nowrap;
		padding: 24px 5px !important;
		color: #303133;
		font-weight: 700;
		margin: 0 auto;
		display: table;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #039705 !important;
		color: #FFF;
	}

	.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #fff !important;
		background-color: #039705 !important;
	}
	.el-tabs--border-card>.el-tabs__header {
	    background-color: transparent !important;
	    border-bottom: none !important;
	    margin: 0;
	}
	.el-tabs--border-card{
		background-color: transparent !important;
		border: none !important; 
		box-shadow: none !important;
	}
	.el-tabs--border-card>.el-tabs__content {
	    padding: 0px !important;
		padding-top: 15px !important;
	}
	.el-message-box{
		width: 530px!important;
	}
	.el-carousel__item:nth-child(2n+1) {
	    background-color: #fff !important;
	}
	.el-carousel__indicators--horizontal {
	    bottom: 9% !important;
	    left: 50%;
	    transform: translateX(-50%);
	}
	.el-carousel__item:nth-child(2n){
		background-color: #fff !important;
	}
</style>