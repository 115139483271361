import {
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Carousel,
	CarouselItem,
	Button,
	Card,
	Collapse,
	CollapseItem,
	Breadcrumb,
	BreadcrumbItem,
	Select,
	Option,
	OptionGroup,
	Form,
	FormItem,
	Input,
	InputNumber,
	Radio,
	RadioGroup,
	RadioButton,
	Upload,
	Switch,
	Dialog,
	Tooltip,
	Pagination,
	MessageBox,
	Message,
	Tabs,
	TabPane,
	Checkbox,
	CheckboxButton,
	CheckboxGroup,
	Drawer,
	Steps,
	Step,
	Image,
	Alert,
	Divider,
	Descriptions,
	DescriptionsItem,
	Result,
	Tag
} from "element-ui";
const element = {
	install: function(Vue) {
		Vue.use(Menu);
		Vue.use(Submenu);
		Vue.use(MenuItem);
		Vue.use(MenuItemGroup);
		Vue.use(Carousel);
		Vue.use(CarouselItem);
		Vue.use(Button);
		Vue.use(Card);
		Vue.use(Collapse);
		Vue.use(Breadcrumb);
		Vue.use(CollapseItem);
		Vue.use(BreadcrumbItem);
		Vue.use(Select);
		Vue.use(Option);
		Vue.use(OptionGroup);
		Vue.use(Form);
		Vue.use(FormItem);
		Vue.use(Input);
		Vue.use(InputNumber);
		Vue.use(Radio);
		Vue.use(RadioGroup);
		Vue.use(RadioButton);
		Vue.use(Upload);
		Vue.use(Switch);
		Vue.use(Dialog);
		Vue.use(Tooltip);
		Vue.use(Pagination);
		Vue.use(Tabs);
		Vue.use(TabPane);
		Vue.use(Checkbox);
		Vue.use(CheckboxButton);
		Vue.use(CheckboxGroup);
		Vue.use(Drawer);
		Vue.use(Steps);
		Vue.use(Step);
		Vue.use(Image);
		Vue.use(Alert);
		Vue.use(Divider);
		Vue.use(Descriptions);
		Vue.use(DescriptionsItem);
		Vue.use(Result);
		Vue.use(Tag);
		
		// 下面这种写法是项目中的$xxx都被elementui对应的组件所替换
		Vue.prototype.$msgbox = MessageBox
		Vue.prototype.$alert = MessageBox.alert
		Vue.prototype.$confirm = MessageBox.confirm
		Vue.prototype.$message = Message
	},
};
export default element;
