import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
     component: () => import('@/views/home/index'),
  },
  {
  	path: "/list/:id",
  	name: "list",
  	meta: {
  		title: '慈善基金'
  	},
  	component: () => import("../views/list")
  },
  {
  	path: "/helpPro",
  	name: "helpPro",
  	meta: {
  		title: '救助项目'
  	},
  	component: () => import("../views/helpPro")
  },
  {
  	path: "/helpInfo/:id",
  	name: "helpInfo",
  	component: () => import("../views/helpInfo")
  },
  {
  	path: "/donationPay",
  	name: "donationPay",
  	meta: {
  		title: '捐赠'
  	},
	  component: () => import("../views/donationPay")
  },
	{
		path: "/donationCheck",
		name: "donationCheck",
		meta: {
			title: '确认捐赠信息'
		},
		component: () => import("../views/donationCheck")
	},
	{
		path: "/donationResult/:id",
		name: "DonationResult",
		meta: {
			title: '确认捐赠信息'
		},
		component: () => import("../views/donationResult")
	},
  {
  	path: "/needHelp",
  	name: "needHelp",
  	meta: {
  		title: '求助信息'
  	},
  	component: () => import("../views/needHelp")
  },
  {
  	path: "/listInfo/:id",
  	name: "listInfo",
  	meta: {
  		title: '详细信息'
  	},
  	component: () => import("../views/listInfo")
  },
  {
  	path: "/helpList/:id",
  	name: "helpList",
  	component: () => import("../views/helpList")
  },
  {
  	path: "/certificate",
  	name: "certificate",
  	component: () => import("../views/certificate")
  },
]

const router = new VueRouter({
  routes
})


// router.beforeEach((to,from,next)=>{
//   next()
// })

// // 导航守卫限制路由跳转
// router.beforeEach((to, from, next) => { 
// 	if (to.path === '/') {
// 		next()
// 		var face_token = ''
// 		// localStorage.getItem("face_token");
// 	}
// 	if (!face_token) {
// 		next()
// 	} else {
// 		next('/')
// 	}
// })
// router  要跟你引用的路由名称一致

export default router
