<template>
	<div class="nav-wrap">
		<div class="box">
            <div class="logo">
                <a href="/#/"></a>
            </div>
			<div class="nav">
				<el-menu :default-active="$route.path" class="navBox"  text-color="black"
					active-text-color="#F92612" mode="horizontal" @select="handleSelect" router >
					<template v-for="(item, index) in navMenuData">
						<el-menu-item :index="item.href" v-if="item.isShow == 0">{{item.name}}
						</el-menu-item>
						<el-submenu :index="/list/+item.id" v-if="item.isShow == 1">
							<template slot="title">{{item.name}}</template>
							<template v-for="items in item.children">
								<template v-if="items.module == 0" style="display:flex">
									<el-menu-item :index="/list/+items.id">{{items.name}}</el-menu-item>
								</template>
								<template v-if="items.module == 1" style="display:flex">
									<el-menu-item :index="'/'+items.href">{{items.name}}</el-menu-item>
								</template>
							</template>
						</el-submenu>
						
					</template>
				</el-menu>
			
			</div>
           
        </div>
	</div>
</template>
<script>
	export default {
		name: "NavMenu",
		data() {
			return {
				id: '',
				module: 0,
				navMenuData: []
				
			};
		},
		created() {
			this.getNavListData();
		},

		methods: {
			handleSelect(key, keyPath) {
				// console.log(key, keyPath);
			},
			onRouteChanged() {
				let that = this
				that.path = that.$route.path
			},
			getNavListData() {
				this.$http.get("api/cms/categoryList?pid=0")
					.then((res) => {
						// console.log('nav',res);
						this.navMenuData = res.data.result;
					})
			},
		},
		watch: {
			// 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
			'$route': 'getNavListData'
		}
	};
</script>

<style>
	.nav-wrap .box {
	    height: 100px;
	    overflow: initial;
	}
	.box {
	    width: 1200px;
	    margin: 0 auto;
	    overflow: hidden;
	}
	.nav-wrap .box .logo {
	    width: 480px;
	    height: 100px;
	    float: left;
	    background: url(../assets/logo.jpg) 0 0 no-repeat;
	}
	.nav-wrap .box .nav {
	    float: right;
	}
	
	.nav {
	    width: 700px;
	    position: relative;
		height: 60px;
		margin-top: 20px;
	}
	.navBox {
		width: 850px !important;
		height: 60px !important;
		margin: 0 auto !important;
	}
	
	.el-menu--horizontal>.el-menu-item {
		height: 60px !important;
		width: 100px !important;
		line-height: 60px !important;
		font-size: 16px !important;
		text-align: center;
	}
	.el-menu-item:hover{
		color: #fa2610 !important;
	}
	
	.el-menu--horizontal>.el-submenu .el-submenu__title {
		width: 120px !important;
		height: 60px !important;
		line-height: 60px !important;
		font-size: 16px !important;
	}
	.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
	    /* background-color: #fff; */
	    color: #fa2610 !important;
	}
	.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		display: none !important;
	}
	
	.el-menu.el-menu--horizontal {
		border: 0 !important;
	}
</style>
