<template>
	<div class="headerBox">
		
		<div class="top-wrap">
		        <div class="box">
		            <div class="left">
		                <div class="phone">
		                    服务热线：0573-85023538
		                </div>
		                <div class="link">
		                    <a href="/#/">网站首页</a>
		                </div>
		            </div>
		            <div class="right">
		                <a class="icon icon-wb"></a>
		                <a class="icon icon-wx"></a>
		            </div>
		        </div>
		    </div>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				
	
			}
		},
		methods: {

		
		
		},
	
		
	}
	
</script>

<style scoped>

	.top-wrap{
		background-color: #f3f3f3;
		width: 100%
	}
	.top-wrap .box{
		height: 50px;
		line-height: 50px;
	}
	.box {
		width: 1200px;
		margin: 0 auto;
		overflow: hidden;
	}
	.top-wrap .box .left {
	}
	.fl, .left {
	    float: left;
	    display: inline;
	}
	.top-wrap .box .right {
	    padding: 13px 0;
	}
	.fr, .right {
	    float: right;
	    display: inline;
	}
	.top-wrap .box .right .icon-wb {
	    background-image: url(../assets/icon_wb.png);
	}
	.top-wrap .box .right .icon {
	    width: 24px;
	    height: 24px;
	    border-radius: 50%;
	    display: block;
	    float: left;
	    margin-left: 24px;
	    background-repeat: no-repeat;
	    background-position: 0 0;
	    background-size: contain;
	}
	.top-wrap .box .right .icon-wx {
	    background-image: url(../assets/icon_wx.png);
	}
	.top-wrap .box .right .icon {
	    width: 24px;
	    height: 24px;
	    border-radius: 50%;
	    display: block;
	    float: left;
	    margin-left: 24px;
	    background-repeat: no-repeat;
	    background-position: 0 0;
	    background-size: contain;
	}
	.top-wrap .box .left .phone {
	    font-size: 14px;
	    color: #4d4d4d;
	    font-weight: bold;
	    text-indent: 24px;
	    background: url(../assets/icon_phone.png) 1px center no-repeat;
	}
	.top-wrap .box .left div {
	    float: left;
	    font-size: 12px;
	}
	.top-wrap .box .left .link {
	    margin-left: 21px;
	}
	.top-wrap .box .left div {
	    float: left;
	    font-size: 12px;
	}

</style>
